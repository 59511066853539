<template>
  <div class="header" :style="headerStyle">
    <div class="container">
      <img class="logo" src="@/assets/img/logo_s.png">
      <div class="right">
        <div class="menu">
          <div :class="{ active: activeIndex === 0 }" class="item" @click="router.push('/')">{{ $t('e9') }}<span></span></div>
          <div :class="{ active: activeIndex === 1 }" class="item" @click="router.push('/mining')">{{ $t('f0') }}<span></span></div>
          <div :class="{ active: activeIndex === 2 }" class="item" @click="router.push('/wallet')">{{ $t('f1') }}<span></span></div>
          <div :class="{ active: activeIndex === 3 }" class="item" @click="router.push('/mine')">{{ $t('f2') }}<span></span></div>
        </div>
        <div class="lang" @click="changeLang">
          <img class="icon" src="@/assets/img/icon_lang.png">
          <div class="text">{{ langText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { useRouter } from "vue-router";

  const router = useRouter()
  defineProps({
    activeIndex: {
      type: Number,
      default: 0
    }
  })

  const langText = ref('')
  langText.value = sessionStorage.getItem('langText') || 'English';

  function changeLang() {
    if(langText.value === 'English') {
      langText.value = '中文'
      sessionStorage.setItem('lang', 'zh')
    } else {
      langText.value = 'English'
      sessionStorage.setItem('lang', 'en')
    }
    sessionStorage.setItem('langText', langText.value)
    window.location.reload()
  }

  const headerStyle = ref({})
  onMounted(() => {
    window.addEventListener('scroll', onScrollChange)
  })

  function onScrollChange() {
    const opacity = window.scrollY / 100 < 1 ? window.scrollY / 100 : 1
    headerStyle.value.backgroundColor = "rgba(116, 65, 192, " + opacity + ")"
  }

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScrollChange)
  })
</script>

<style scoped lang="less">
.header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  max-width: 1920px;
  transform: translateX(-50%);
  z-index: 99;

  .container{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 16px 0;
    align-items: center;
    justify-content: space-between;

    .logo{
      width: 120px;
    }
    .right{
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 16px;

      .menu{
        display: flex;
        align-items: center;
        line-height: 22px;

        .item{
          margin-right: 36px;
          cursor: pointer;
          padding: 4px 0;
          position: relative;
        }
        .active{
          span{
            width: 100%;
            height: 2px;
            background: #FFFFFF;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
      .lang{
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon{
          width: 22px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
